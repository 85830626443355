<template lang="pug">
q-card#form.modal-form-card
  q-card-section
    form-header(
      ref="form-header",
      @close-form="closeForm",
      :parentData="{ grid: grid, title: header_title, title_icon: 'download_header.svg' }"
    )

  .spinner-container.modal-spinner(v-if="!data_has_loaded && loading")
    q-spinner(color="primary", size="3em")

  q-card-section(v-if="data_has_loaded", v-show="!loading")
    q-form
      component(
        v-for="field in fields",
        :key="field.name",
        :is="getFieldComponent(field.type)",
        @fields-loaded="fieldsLoaded",
        :parentData="{ grid: grid, data: getFieldData(field) }"
      )

  q-card-actions.button-group.row.justify-center(v-if="data_has_loaded && !loading")
    q-btn.modal-form-cancel(flat, no-caps, :label="form.form_buttons.cancel", @click="closeForm()")
    q-btn.modal-form-submit(flat, no-caps, :label="form.form_buttons.submit", @click="submitForm()")
</template>

<script>
// Form is used in cleaning journal page
import formHeader from "../../shared/forms/formHeader";
import fields from "./fields";

import { useEmitter } from "@/services/useEmitter";

export default {
  components: {
    formHeader,
    fields,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      data: {},
      path: this.parentData.path,
      grid: this.parentData.grid,
      loading_fields: [],
      loading: true,
      data_has_loaded: false,
      form_valid: false,
      emitter: useEmitter(),
    };
  },

  computed: {
    header_title() {
      return this.parentData.verify_checkins_attr.header_title;
    },

    form() {
      return this.data.form;
    },

    fields() {
      return this.data.fields;
    },
  },

  watch: {
    loading(newVal, oldVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"]?.setLoading(newVal);
      }
    },
  },

  async created() {
    this.resetForm();
    await this.loadFormData();
  },

  methods: {
    closeForm() {
      this.resetForm();
      this.$emit("close-export", {});
    },

    resetForm() {
      const fields = Object.keys(this.currentForm);
      this.$store.commit("resetFormAllFields", { grid_name: this.grid, fields: fields });
    },

    getFieldComponent(key) {
      return fields[key];
    },

    getFieldData(field) {
      return this.fields.filter(f => f == field);
    },

    fieldsLoaded(field = undefined) {
      if (this.loading_fields.length === 0) {
        this.loading_fields = this.fields
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (field) {
        this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString());
      }

      if (this.loading_fields.length === 0) {
        this.loading = false;
        this.emitter.emit("allFieldsLoaded");
      }
    },

    isValid() {
      this.checkFormValidation(this.currentForm); // writes result into this.form_valid

      return this.form_valid;
    },

    submitForm() {
      if (!this.isValid()) {
        return;
      }

      const attr = {
        report_params: {
          filters: this.generateFiltersParams(),
        },
      };

      this.loading = true;
      this.createReport(attr);
    },

    generateFiltersParams() {
      return Object.keys(this.currentForm).reduce((result, e) => {
        let field = this.currentForm[e]["field"];

        if (field) {
          if (Array.isArray(this.currentForm[e]["field"])) {
            result[e] = field.map(e => {
              if (e["value"]) {
                return e["value"];
              } else {
                return e;
              }
            });
          } else if (typeof field === "object") {
            result[e] = field["value"];
          } else {
            result[e] = field;
          }
        }
        return result;
      }, {});
    },

    createReport(params) {
      if (!this.report_id_waiting) {
        return this.$backend
          .create(this.path + "/verify_checkins_create_report", null, params)
          .then(({ data }) => {
            this.$root.$emit("init-report-intervals", data);
            this.$q.notify(this.notifies.report_in_process);
            this.$emit("close", {});
          })
          .catch(error => {
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.loading = false;
            this.$q.notify(this.notifies.error_ask_admin);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.$q.notify(this.notifies.wait_report_generated);
      }
    },

    async loadFormData() {
      try {
        const response = await this.$backend.index(`${this.path}/verify_checkins_form_data`);
        this.data = response.data;
      } catch (e) {
        console.log(e);
        if (e.response) {
          this.reLogin(e.response.status);
        }
      } finally {
        setTimeout(() => {
          this.data_has_loaded = true;
          this.fieldsLoaded();
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/modal-form";
@import "../../../assets/styles/forms/fields/checkbox";
</style>
