<template lang="pug">
#store.equipment-tech-map
  .spinner-container.form-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  .tech-map-card(v-if="!loading")
    .techmap-info-blocks
      .spinner-container.form-spinner(v-if="loading_blocks")
        q-spinner(color="primary", size="3em")

      .specification-block(v-if="!loading_blocks")
        history(
          v-if="row && row.consumptions_history",
          :parentData="{ buttons: data['edit_consumptions_history_buttons'], tab_conf: tab.data, editable, grid, row_id, data: row && row.consumptions_history ? row.consumptions_history.consumptions_table : [] }",
          @load-page="loadTableData"
        )

  grid-modals(
    ref="grid-modals",
    @reload-data="reloadTable",
    :parentData="{ grid: grid, path: `/api/v3/stores/${parentData.row.id}/consumptions_history` }"
  )
</template>

<script>
import history from "../../history/history";
import ImportExcelForm from "@/components/shared/forms/importExcelForm";
import gridModals from "@/components/shared/grid/gridModals";
import { handleError } from "@/services/handleErrors";

export default {
  components: {
    ImportExcelForm,
    history,
    gridModals,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      item: "stores",
      row_id: this.parentData.row_id,
      tab_name: this.parentData.tab_name,
      row: {},
      data: [],
      editable: false,
      loading: true,
      loading_blocks: false,
      tab: "",
    };
  },

  computed: {
    buttons() {
      return this.data.actions;
    },

    fileIcon() {
      return require(`../../../../../assets/icons/header/file.svg`);
    },

    tech_map_data() {
      let grid = this.$store.state.grid[this.grid];
      if (grid) {
        return grid["consumptions_history"]["components"];
      } else {
        return undefined;
      }
    },
  },

  created() {
    this.$store.commit("initialConsumptionsHistory", { grid_name: this.grid });
    this.loadData();
  },

  methods: {
    tabCondition(tab_name) {
      return tab_name !== "issues" || (tab_name === "issues" && this.getData(tab_name).length > 0);
    },

    getButtonIcon(button) {
      return require(`../../../../../assets/icons/ppr/equipment/show/actions/${button.icon}`);
    },

    ifButtonIconExists(button) {
      return button.icon && button.icon.length > 0;
    },

    ifButtonLabelExists(button) {
      return button.label && button.label.length > 0;
    },

    getData(tab_name) {
      if (tab_name) {
        if (tab_name === "issues") {
          return this.row[tab_name];
        } else {
          return this.row["technological_map"][tab_name];
        }
      }
    },

    addCurrentTab(tab) {
      this.tab = tab;
    },

    updateButtonStyle(button) {
      if (button.onclick && button.onclick.child) {
        this.buttons.find(b => b.name === button.name).style.wrapper.display = "none";
        this.buttons.find(b => b.name === button.onclick.child).style.wrapper.display = "initial";
      } else if (button.onclick && button.onclick.parent) {
        this.buttons.find(b => b.name === button.name).style.wrapper.display = "none";
        this.buttons.find(b => b.name === button.onclick.parent).style.wrapper.display = "initial";
      }

      if (button.onclick && button.onclick.fn_name) {
        this[button.onclick.fn_name](button.onclick.attr);
      }
    },

    setEditable(attr) {
      this.editable = attr.value;
      if (!attr.value) {
        this.loading_blocks = true;
        this.loadData();
      }
    },

    async loadTableData(params = false) {
      try {
        let loadPath = `api/v3/stores/${this.parentData.row.id}/consumptions_history`;

        if (params) {
          const loadParams = { infinite_scroll: { page: params.page, per_page: 20 } };

          const data = (await this.$backend.collection(loadPath, loadParams)).data;

          console.log(data);

          if (this.row && this.row.consumptions_history) {
            console.log(this.row.consumptions_history.consumptions_table[0].data, data.data);
            this.row.consumptions_history.consumptions_table[0].data = [
              ...(this.row?.consumptions_history?.consumptions_table[0]?.data || []),
              ...data.data,
            ];

            this.row.count = data.count;
          }
        }
      } catch (e) {
        await handleError(e);
      }
    },

    async reloadTable(params = false) {
      try {
        let searchString = `/api/v3/stores/${this.parentData.row.id}/consumptions_history/collection`;
        if (params) {
          const descending = params.sort_direction === "down";
          const getParams = `{"table":{"rowsPerPage":20,"sortBy":"${params.sort}","descending":${descending},"query":"${params.search}","filters":{"store_id":${this.parentData.row.id}}}}`;

          searchString = "/api/v3/stores/" + this.parentData.row.id + "/consumptions_history" + "?json=" + getParams;
        }

        const data = (await this.$backend.index(searchString)).data;

        if (params) {
          if (this.row && this.row.consumptions_history)
            this.row.consumptions_history.consumptions_table[0].data = data.data;
        } else {
          if (this.row && this.row.consumptions_history)
            this.row.consumptions_history.consumptions_table[0].data = data.options;
        }
      } catch (e) {
        await handleError(e);
      }
    },

    async loadData(params = {}) {
      try {
        const data = (await this.$backend.show(this.path, this.row_id, { params: params })).data;
        console.log(data);
        this.row = data["common"]["data"];
        this.data = data["common"]["tabs"].find(tab => tab.name === this.tab_name)["data"];
        this.tab = this.data.tabs[0];

        this.$nextTick(() => {
          let keys = Object.keys(this.row.consumptions_history);
          if (keys.length > 0) {
            keys.forEach(key => {
              if (key === "consumptions_history") {
                if (this.row && this.row["consumptions_table"][key].map(s => s.required).length > 0) {
                  this.row["consumptions_table"][key].map(s => delete s.required);
                }
                if (this.row && this.row["consumptions_table"][key].filter(el => el.name).length > 0) {
                  this.row["consumptions_table"][key] = this.rewriteKeysTempLogic(this.row["technological_map"][key]);
                }
              }

              this.$store.commit("addConsumptionsHistory", {
                grid_name: this.grid,
                tech_map_key: key,
                attr:
                  this.row && this.row["consumptions_history"]
                    ? this.row["consumptions_history"]["consumptions_table"][0]["data"]
                    : [],
              });
            });
          }
        });
      } catch (error) {
        await handleError(error);
      } finally {
        this.loading = false;
        this.loading_blocks = false;
      }
    },

    rewriteKeysTempLogic(obj) {
      return obj.map(el => {
        return Object.keys(el).reduce((r, key) => {
          if (key === "name") {
            r["key"] = el[key];
          } else {
            r[key] = el[key];
          }
          return r;
        }, {});
      });
    },
  },
};
</script>

<style lang="scss">
#store .techmap-info-blocks,
#store .equipment-tech-map,
#store .tabs-block {
  min-height: 0;
}

#store {
  .specification-title span {
    font-size: 20px !important;
    color: var(--tab-text-color) !important;
  }
  &.equipment-tech-map .tech-map-card .specification-block {
    margin-top: 58px;
  }
  .equipment-tech-map {
    border-bottom: var(--tabs-block-td-border) !important;
    border-right: var(--tabs-block-td-border) !important;
    position: relative;
    .tech-map-card {
      .tabs-block {
        max-height: calc(66vh - 100px);
        margin-top: 0;
        td {
          padding: 10px;
        }
        thead {
          position: absolute;
          display: table;
          table-layout: fixed;
          top: -40px;
        }
        tr {
          display: table;
          table-layout: fixed;
          width: 100%;
        }
      }
    }
  }
}

.materials-import-button {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 10px;
  border-radius: 8px;
  background-color: #5b7aff;
  color: #fff;
}

#components-table.stores td {
  color: var(--tab-text-color);
}
</style>
