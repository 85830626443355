<template lang="pug">
.date_filter.form-date
  .form-field-label.filter
    q-input.date-input-class(
      v-model="currentFilter",
      :label="label",
      :id="name",
      :mask="currentMask",
      @click.stop="openDateModal",
      readonly
    )
      template(v-slot:prepend)
        inline-svg.datetime-icon(:src="require(`@/assets/icons/calendar.svg`)", @click.stop="openDateModal")
    q-dialog#q-datetime-modal(v-model="openModal")
      q-card.row.radius(flat)
        q-date(
          v-model="currentDate",
          :mask="mask",
          :locale="currentDatesLocale",
          :default-view="defaultView",
          :options="dateOptions",
          today-btn
        )
          .row.justify-end
            q-btn.dialog-controls(
              flat,
              @click="openModal = false",
              :label="buttonCancelLabel",
              v-if="type !== 'datetime'"
            )
            q-btn.dialog-controls(flat, @click="checkDate", :label="buttonCloseLabel", v-if="type !== 'datetime'")
        q-time(v-model="currentDate", :mask="mask", format24h, v-if="type === 'datetime'", now-btn)
          .row.justify-end
            q-btn.dialog-controls(
              flat,
              @click="openModal = false",
              :label="buttonCancelLabel",
              v-if="type !== 'datetime'"
            )
            q-btn.dialog-controls(flat, @click="checkDate", :label="buttonCloseLabel", v-if="type === 'datetime'")
  q-icon.date_filter__cancel(name="cancel", v-if="isResettable", @click.stop="resetFilter")
</template>

<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "@/store";
import { currentLocale } from "@/services/useLocales";
import { Datetime } from "vue-datetime";
import { format, parse } from "date-fns";
import { formatDateAsISO } from "@/services/formatDateAsISO";
import { Settings } from "luxon";
import i18n from "@/plugins/vue-i18n";

const props = defineProps({
  parentData: { type: Object, default: () => {} },
});

const store = useStore();

const phrases = ref({});
const openModal = ref(false);
const currentDate = ref("");

const type = computed(() => props.parentData.data[0].type);
const label = computed(() => props.parentData.data[0].label);
const name = computed(() => props.parentData.data[0].name);
const value = computed(() => props.parentData.data[0].value);
const defaultView = computed(() => props.parentData.data[0].defaultView || "Calendar");
const minDate = computed(() => props.parentData.data[0].minDate || undefined); // Minimum date to allow user to select in dd.mm.yyyy format. Example: "01.01.2020"
const maxDate = computed(() => props.parentData.data[0].maxDate || undefined); // Maximun date to allow user to select in dd.mm.yyyy format. Example: "02.02.2022"
const reset = computed(() => (props.parentData.data[0].reset === undefined ? true : props.parentData.data[0].reset));
const grid = computed(() => props.parentData.grid);
const path = computed(() => props.parentData.path);
const mask = computed(() => props.parentData.mask || "DD.MM.YYYY");

const currentDatesLocale = computed(() => i18n["messages"][currentLocale.value]["date"]);
const buttonCloseLabel = computed(() => i18n["messages"][currentLocale.value]["buttonCloseLabel"]);
const buttonCancelLabel = computed(() => i18n["messages"][currentLocale.value]["buttonCancelLabel"]);

const currentMask = computed(() => (props.mask === "DD.MM.YYYY, HH:mm" ? "##.##.####, ##:##" : "##.##.####"));
const currentFilters = computed(() => store.state.grid[grid.value]["filters"]);

const currentFilter = computed({
  // getter
  get() {
    const formField = currentFilters.value[name.value];

    if (!formField) {
      return undefined;
    }

    // we need to ensure date is formatted as ISO string
    const dateArr = formField.split("T");

    if (dateArr.length > 1) {
      const dateInstance = new Date(formField);
      if (type.value === "datetime") {
        return format(dateInstance, "dd.MM.yyyy, HH:mm");
      } else {
        return format(dateInstance, "dd.MM.yyyy");
      }
    } else {
      return formField;
    }
  },
  // setter
  set(newValue) {
    let val = newValue;

    if (val) {
      // we need to ensure date is formatted as ISO string
      val = formatDateAsISO(val);
    }

    // Fix for the case when we perform full reset in store via removeAllFilters/resetAllFilters vuex action
    if (val === "") {
      return;
    }

    store.commit("updateFilter", { grid_name: grid.value, filter: name.value, value: val });
  },
});
const isResettable = computed(() => {
  if (!currentFilter.value) {
    // if default/value is not set
    return false;
  }

  return reset.value;
});

const openDateModal = () => {
  openModal.value = true;
  currentDate.value = getCurrentDate(mask.value);
};

const resetFilter = () => {
  store.commit("resetFilter", { grid_name: grid.value, filter: name.value });
};

const checkDate = () => {
  currentFilter.value = currentDate.value;
  openModal.value = !openModal.value;
};

const getCurrentDate = mask => {
  if (currentFilter.value) {
    return currentFilter.value;
  } else {
    const dateTime = new Date();

    if (mask === "DD.MM.YYYY, HH:mm") {
      return format(dateTime, "dd.MM.yyyy, HH:mm");
    } else {
      return format(dateTime, "dd.MM.yyyy");
    }
  }
};

const setCurrentDate = () => {
  currentDate.value = type.value === "datetime" ? getCurrentDate("DD.MM.YYYY, HH:mm") : getCurrentDate("DD.MM.YYYY");
};

const setDateFieldLocale = () => {
  if (Settings.defaultLocale !== currentLocale.value) {
    Settings.defaultLocale = currentLocale.value; // localization for vue-datetime (date_field, date_filter)
  }
};

const setDateFieldValue = () => {
  if (!value.value || currentFilter.value) {
    return;
  }

  currentFilter.value = value.value;
};

const parsedCalendarDate = date => {
  const dateMask = "yyyy/MM/dd";

  return parse(date, dateMask, new Date());
};

// Set max/min available date
const dateOptions = date => {
  const minMaxMast = "dd.MM.yyyy";

  if (minDate.value === undefined && maxDate.value === undefined) {
    return true;
  } else if (minDate.value && maxDate.value === undefined) {
    const calendarDate = parsedCalendarDate(date);
    const minimalDate = parse(minDate.value, minMaxMast, new Date());
    const allowDate = calendarDate >= minimalDate;

    return allowDate;
  } else if (minDate.value === undefined && maxDate.value) {
    const calendarDate = parsedCalendarDate(date);
    const maximumDate = parse(maxDate.value, minMaxMast, new Date());
    const allowDate = calendarDate <= maximumDate;

    return allowDate;
  } else {
    return true;
  }
};

onBeforeMount(() => {
  setCurrentDate();
  setDateFieldLocale();
  setDateFieldValue();
});
</script>

<style scoped lang="scss">
.date_filter {
  :deep(.q-field__control) {
    border: none !important;
  }

  :deep(.q-field__native) {
    cursor: pointer;
  }

  :deep(.q-field__label) {
    font-size: 14px !important;
  }

  .date_filter__cancel {
    position: absolute;
    right: 16px;
    bottom: 14px;
    margin-top: 17px;
    cursor: pointer;
    color: var(--field-cancel-icon-color);
  }
}
</style>
