export const generateFiltersParams = filters => {
  let params = {};
  Object.keys(filters).map(key => {
    const filter = filters[key];

    if (filter) {
      if (Array.isArray(filter) && filter.length > 0) {
        params[key] = filter.map(el => el.value);
      } else if (Object.keys(filter).length !== 0 && filter.constructor === Object) {
        params[key] = filter.value;
      } else if ((typeof filter === "string" && filter !== "") || [true, false].includes(filter)) {
        params[key] = filter;
      }
    } else {
      delete params[key];
    }
  });
  return params;
};
