<template lang="pug">
q-dialog(ref="active", v-model="active", persistent, transition-show="slide-left", transition-hide="slide-right")
  q-card.floor-map-dialog-card
    q-card-section
      .floor-plan-dot-detail
        .spinner-container(v-if="loading")
          q-spinner(color="primary", size="3em")
        .floor-plan-dot-detail-container(v-else)
          div(v-if="this.dot.type === 'equipments'")
            .floor-plan-dot-detail-header
              .floor-plan-dot-detail-header-left
                inline-svg.form-card-title-icon(:src="require(`../../../../assets/icons/form/header/file_header.svg`)")
                p {{ `${this.type} №${this.detailInfo.id}` }}
              q-btn(dense, flat, icon="close", @click="hideDetail")
            q-separator
            .floor-plan-dot-detail-tech-map
              .floor-plan-dot-detail-specification
                .floor-plan-dot-detail-specification-item(v-for="item in detailInfo.specification")
                  .floor-plan-dot-detail-specification-item-left {{ item.key || item.name }}
                  .floor-plan-dot-detail-specification-item-right {{ item.value || "–" }}
              .floor-plan-dot-detail-tabs
                q-card
                  q-tabs.text-grey(
                    v-model="componentsTab",
                    dense,
                    active-color="primary",
                    indicator-color="primary",
                    align="justify",
                    narrow-indicator
                  )
                    q-tab(name="components", label="Компоненты")
                    q-tab(name="replaced_components", label="Замена компонентов")
                    q-tab(v-if="detailInfo.issues.length > 0", name="issues", label="Заявки")

                  q-separator

                  q-tab-panels(v-model="componentsTab", animated)
                    q-tab-panel(name="components")
                      detailTabs(:parentData="{ tabs: detailInfo.components }")
                    q-tab-panel(name="replaced_components")
                      detailTabs(:parentData="{ tabs: detailInfo.replaced_components }")
                    q-tab-panel(name="issues")
                      detailTabsIssues(:parentData="{ tabs: detailInfo.issues }")
          div(v-if="this.dot.type === 'meters'")
            .floor-plan-dot-detail-header
              .floor-plan-dot-detail-header-left
                inline-svg.form-card-title-icon(:src="require(`../../../../assets/icons/form/header/file_header.svg`)")
                p {{ `${this.type} №${this.detailInfo.id}` }}
              q-btn(dense, flat, icon="close", @click="hideDetail")
            q-separator
            .floor-plan-dot-detail-tech-map
              .floor-plan-dot-detail-specification
                .floor-plan-dot-detail-specification-item(v-for="item in detailInfo.recording")
                  .floor-plan-dot-detail-specification-item-left {{ item.date }}
                  .floor-plan-dot-detail-specification-item-right {{ item.record || "–" }}
</template>

<script>
import detailTabs from "./detail_tabs.vue";
import detailTabsIssues from "./detail_issue-tabs.vue";

export default {
  components: {
    detailTabs,
    detailTabsIssues,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      dot: this.detailData.dot,
      active: this.detailData.active,
      detailInfo: {},
      loading: false,
      type: "",
      componentsTab: "components",
    };
  },

  beforeMount() {
    this.getDetailInfo();
  },

  methods: {
    getDetailInfo(type = this.dot.type) {
      this.loading = true;
      switch (this.dot.type) {
        case "equipments":
          this.type = "Оборудование";
          break;
        case "meters":
          this.type = "Счетчик";
          break;

        default:
          this.type = "Оборудование";
          break;
      }
      if (type === "equipments") {
        this.$backend.index(`api/v3/ppr/${this.dot.type}/${this.dot.value}`, {}).then(res => {
          const data = res.data.common.data;
          this.detailInfo["id"] = data.id;
          this.detailInfo["specification"] = data.technological_map.specification;
          this.detailInfo["components"] = data.technological_map.components;
          this.detailInfo["replaced_components"] = data.technological_map.replaced_components;
          this.detailInfo["issues"] = data.issues;
          console.log(data);
          this.loading = false;
        });
        return;
      }
      if (type === "sensors") {
        return;
      }
      if (type === "meters") {
        this.$backend.index(`api/v3/measuring/${this.dot.type}/${this.dot.value}`).then(res => {
          const data = res.data;
          this.detailInfo["id"] = data.id;

          this.$backend
            .index(`api/v3/measuring/meter_readings/collection?measuring_meter_id=${this.dot.value}`)
            .then(resp => {
              const options = resp.data.options;
              const recordings = [];
              options.forEach(item => {
                const obj = {
                  record: item.title,
                  date: item.reading_at,
                };
                recordings.unshift(obj);
              });
              this.detailInfo["recording"] = recordings;

              this.loading = false;
            });
        });
      }
    },

    hideDetail() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/floor-plan/main";
</style>
