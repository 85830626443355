<template lang="pug">
.bar
  .bar__header
    .bar__label {{ values?.legend?.title }}
    .bar__legend 
      .bar__legend__item(v-for="item in values?.legend?.items", :key="item.title")
        .bar__legend__item__circle(:style="`background-color: ${item.color}`")
        .bar__legend__item__label {{ item.title }}

  apexchart(type="bar", :height="300", :options="chartOptions", :series="series", v-if="!loading")
</template>

<script setup>
import { backend } from "@/api";
import { ref, onBeforeMount, onBeforeUnmount } from "vue";

import { useEmitter } from "@/services/useEmitter";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { useStore } from "@/store";

import { currentLocale } from "@/services/useLocales";

import i18n from "@/plugins/vue-i18n";

const emitter = useEmitter();

const store = useStore();

const props = defineProps({
  path: { type: String, required: true },
});

const loading = ref(true);

const chartOptions = ref({
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  fill: {
    opacity: 1,
  },
  colors: ["#5B7AFF", "#B5BBC9"],
  legend: {
    show: false,
  },
  xaxis: {
    categories: [],
  },
});

const series = ref([]);
const values = ref([]);

const getData = async () => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["expenses_dashboard"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  const data = (await backend.collection(props.path, { query: localQuery, filters: JSON.stringify(localFilters) }))
    .data;

  chartOptions.value.xaxis.categories = data.categories.map(el => getMonth(new Date(el).getMonth()));
  values.value = data;
  series.value = [data.ppr, data.service];
};

const getMonth = month => {
  return i18n["messages"][currentLocale.value]["date"]["months"][month];
};

onBeforeMount(async () => {
  await getData();

  loading.value = false;

  emitter.on("refresh-expenses-dashboard-data", getData);
});

onBeforeUnmount(() => {
  emitter.off("refresh-expenses-dashboard-data");
});
</script>

<style lang="scss">
.bar {
  width: 100%;

  background: var(--main-background-color);
  box-shadow: var(--expenses-dashboard-row-item-shadow);

  border-radius: 20px;
  border: 1px solid gray;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &__legend {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      align-items: center;
      gap: 5px;

      &__circle {
        width: 8px;
        height: 8px;

        border-radius: 50%;

        background-color: #5b7aff;
      }
      &__label {
        font-weight: 40;
        font-size: 14px;
        color: #8a9099;
      }
    }
  }

  &__label {
    color: var(--header-title-color);
    font-size: 20px;
  }
  & .apexcharts-series path {
    clip-path: inset(0 0 0 0 round 15px);
  }
  & .apexcharts-tooltip {
    border: 0.5px solid grey !important;
    background: var(--expenses-dashboard-custom-back) !important;
    color: var(--header-title-color) !important;
  }
  & .apexcharts-tooltip-title {
    background: transparent !important;
    border-bottom: 0.5px solid grey !important;
  }
  & .apexcharts-xaxis-label,
  & .apexcharts-yaxis-label {
    fill: var(--header-subtitle-color);
  }
}
</style>
