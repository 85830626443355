<template lang="pug">
.pie
  .pie__header
    .pie__label {{ values?.legend?.title }}
    .pie__header__text
      span.pie__percentage {{ values?.legend?.result?.value }}
      span.pie__description {{ values?.legend?.result?.label }}

  .pie__content
    .pie__chart-container
      apexchart(type="donut", :height="200", :options="chartOptions", :series="values?.data", v-if="!zeroValues")
      span(v-else) Нет данных
    .pie__legend
      .pie__legend__by-params 
        .pie__legend__item(v-for="item in values?.items", :key="item.label")
          .pie__legend__item__circle(:style="`background-color: ${item.color}`")
          .pie__legend__item__content 
            .pie__legend__item__label {{ item.label }}
            .pie__legend__item__value {{ item.value }}

      .pie__legend__separator
      .pie__legend__all
        .pie__legend__all__item(v-for="item in values?.result", :key="item.label")
          .pie__legend__all__item__label {{ item.label }}
          .pie__legend__all__item__value {{ item.value }}
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { backend } from "@/api";
import { useEmitter } from "@/services/useEmitter";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { useStore } from "@/store";

const emitter = useEmitter();

const store = useStore();

const props = defineProps({
  path: { type: String, required: true },
});

const chartOptions = {
  chart: {
    type: "donut",
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    followCursor: true,
    custom: function (opts) {
      // candlestick standard
      const data = opts.series[opts.seriesIndex];
      const label = opts.w.config.labels[opts.seriesIndex];
      const color = opts.w.config.colors[opts.seriesIndex];

      let text = `<div class='column-chart__tooltip'><div class='column-chart__tooltip__header'><span class='column-chart__tooltip__circle' style='background-color: ${color}'></span>"`;
      text += `<span class='column-chart__tooltip__label'>${data} ₽</span></div>`;
      text += `<span class='column-chart__tooltip__value'>${label}</span></div>`;

      return text;
    },
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      dataLabels: {
        offset: -2,
      },
      donut: {
        size: "55%",
        labels: {
          name: {
            fontSize: "12px",
          },
        },
      },
    },
  },
  colors: ["#FA7167", "#FB9390", "#5B7AFF", "#7896F4"],
  labels: [
    "Потрачено на работы ППР",
    "Потрачено на материалы ППР",
    "Потрачено на работы Сервисные",
    "Потрачено на материалы Сервисные",
  ],
  legend: {
    show: false,
  },
};

const values = ref();

const zeroValues = ref();

const getData = async () => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["expenses_dashboard"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  const data = (await backend.collection(props.path, { query: localQuery, filters: JSON.stringify(localFilters) }))
    .data;

  values.value = data;
  if (values.value.data.filter(el => el !== 0).length === 0) zeroValues.value = true;
};

onMounted(async () => {
  await getData();

  emitter.on("refresh-expenses-dashboard-data", getData);
});

onBeforeUnmount(() => {
  emitter.off("refresh-expenses-dashboard-data");
});
</script>

<style lang="scss">
.pie {
  width: 100%;

  background: var(--main-background-color);
  box-shadow: var(--expenses-dashboard-row-item-shadow);
  border: 1px solid gray;

  border-radius: 20px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0 20px;
  }

  &__label {
    color: var(--header-title-color);
    font-size: 20px;

    margin-left: 0;
  }

  &__percentage {
    color: var(--header-title-color);
    font-weight: 600;
  }

  &__description {
    margin-left: 5px;

    font-weight: 500;
    color: #a5a5a5;
  }

  &__content {
    height: calc(100% - 51px - 25px);

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;

    margin-top: 20px;
    padding: 20px;
  }

  &__chart-container {
    width: 200px;
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    background: var(--expenses-dashboard-custom-back);
  }

  &__legend {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: min-content;
    gap: 5px;

    &__separator {
      border: 0.5px solid #b5bbc9;
    }

    &__by-params {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }

    &__all {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      &__item {
        min-width: 45%;
        padding-left: 13px;

        &__label {
          font-weight: 500;
          color: #a5a5a5;
        }

        &__value {
          font-weight: 500;
          color: var(--header-title-color);
        }
      }
    }

    &__item {
      min-width: 45%;

      display: flex;
      gap: 5px;

      &__circle {
        width: 8px;
        height: 8px;

        margin-top: 6px;

        border-radius: 50%;

        background-color: #5b7aff;
      }

      &__label {
        font-weight: 500;
        color: #a5a5a5;
      }

      &__value {
        font-weight: 500;
        color: var(--header-title-color);
      }
    }
  }

  path {
    stroke: unset !important;
  }
}
</style>
