<template lang="pug">
q-dialog(:value="show", @hide="closeForm")
  q-card.filters-save-form__card
    .row.justify-between
      .filters-save-form__title
        q-icon.filters-save-form__title__icon
          img(src="../../../../../src/assets/icons/draft.svg")
        span.filters-save-form__title__text {{ headerTitle }}

    stringField.filters-save-form__name-field(
      :label="nameField?.label",
      :initialValue="title",
      :required="nameField?.require",
      @change="onNameChange"
    )

    .filters-save-form__buttons-group
      button(type="secondary", :label="dynamicIssuesLocales.filters.cancel", @click="closeForm")
      button(
        v-if="id",
        type="primary",
        :label="dynamicIssuesLocales.filters.update",
        @click="onChange",
        :disable="isSaveDisabled"
      )
      button(
        v-else,
        type="primary",
        :label="dynamicIssuesLocales.filters.save",
        @click="onSave",
        :disable="isSaveDisabled"
      )
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from "vue";
import StringField from "@/components/shared/general_components/fields/stringField.vue";
import Button from "@/components/shared/general_components/inputs/Button.vue";
import backend from "@/api";
import { handleError } from "@/services/handleErrors";
import { dynamicIssuesLocales } from "@/services/useLocales";

const props = defineProps({
  /* 
  Имя фильтра
  */
  name: { type: String || null, required: false, default: null },
  /* 
  Идентификатор фильтра
  */
  id: { type: String || Number, required: false, default: null },
  /* 
  Показатель видимости формы
  */
  show: { type: Boolean, required: true, default: false },
  /* 
  Идентификатор фасилити
  */
  facilityId: { type: Number, required: true },
  /* 
  Объект с выбранными фильтрами, содержит все фильтры в стандартном для них формате
  */
  selectedFilters: { type: Object, required: true, default: () => {} },
});

const emit = defineEmits([
  /* 
  При закрытии формы
  */
  "closeForm",
  /*
  При отправке формы на обновление, отправляет идентификатор фильтра
  */
  "filtersUpdated",
]);

const title = ref(props.name);
const formData = ref(null);

const formHeader = computed(() => formData.value?.form_header);

const headerTitle = computed(() => (!props.id ? formHeader.value?.title?.add : formHeader.value?.title?.edit));

const nameField = computed(() => formData.value?.fields[0]);

const isSaveDisabled = computed(() => {
  return nameField.value?.require && !title.value;
});

const closeForm = () => {
  if (!props.id) {
    resetValue();
  }
  emit("closeForm");
};

const onNameChange = val => {
  title.value = val.value;
};

const prepareFilters = () => {
  // для совместимости с фильтрами, которые сохраняются на бэк и отображаются
  return Object.fromEntries(
    Object.entries(props.selectedFilters).map(([name, content]) => {
      const outputContent = Array.isArray(content) ? content.map(item => item.value) : content;

      return [name, outputContent];
    }),
  );
};

const onChange = async () => {
  try {
    const filters = prepareFilters();

    const params = {
      issue_filter: {
        title: title.value,
        ...filters,
      },
    };

    const response = await backend.patch(
      `/api/v3/dynamic/issue_filters/${props.id}?facility_id=${props.facilityId}`,
      params,
    );
    emit("filtersUpdated", props.id);
    closeForm();
  } catch (e) {
    await handleError(e);
  }
};

const onSave = async () => {
  try {
    const filters = prepareFilters();

    const params = {
      issue_filter: {
        title: title.value,
        ...filters,
      },
    };

    const response = await backend.create(
      `/api/v3/dynamic/issue_filters?facility_id=${props.facilityId}`,
      null,
      params,
    );
    closeForm();
  } catch (e) {
    await handleError(e);
  }
};

onMounted(async () => {
  await getFormData();
});

const resetValue = () => {
  title.value = "";
};

const getFormData = async () => {
  try {
    const response = await backend.index(`/api/v3/dynamic/issue_filters/form_data`);
    formData.value = response.data;
  } catch (e) {
    await handleError(e);
  }
};
</script>

<style lang="scss" scoped>
.filters-save-form {
  &__card {
    border-radius: 20px !important;
    color: var(--expansion-list-text-color);
    background: var(--expansion-list-background);
    width: 600px;
    padding: 32px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    gap: 10px;
    &__icon {
      width: 24px;
      height: 24px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__name-field {
    margin-top: 32px;
  }
  &__buttons-group {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
