<template lang="pug">
div 
  transition(name="fade")
    .removed-badge(v-show="deleted") 
      q-badge(color="grey", transparent) 
        span {{ localesData.labels.metric_deleted }}.&nbsp;
        span.removed-badge__recover(@click="onRecover") {{ localesData.labels.recover }}

  transition(name="fade")
    .metric(v-show="!deleted") 
      .metric-settings
        .settings__name {{ getFieldValue("name") }}

        .settings__options
          inline-svg.options__icon(:src="require(`../../../../../assets/icons/grid/actions.svg`)")

          q-menu(content-class="hide-overflow metric__settings__menu")
            q-list
              q-item(clickable, v-close-popup, @click="onDelete")
                q-item-section {{ localesData.buttons.delete }}
      .metric-row
        .field__item(v-for="field in renderableFields", :key="field.name")
          component(
            :is="getFieldComponent(field.type)",
            :required="getFieldRequire(field.name)",
            :label="getFieldLabel(field.name)",
            :initial-value="getFieldValue(field.name)",
            :options-path="getFieldOptionsPath(field.name)",
            :options-params="getFieldOptionsParams(field.name)",
            @change="onChange(field, $event)",
            @mounted="onSelectMounted(field.name)",
            @initial-load="onSelectInitialLoad(field.name)"
          )
      div 
        div(v-if="typeField.value === 'simple'")
          coefficient(
            :index="1",
            simple-metric,
            :locales-data="localesData",
            :value="coefficentsValue?.['coefficient_1']",
            :coefficent-fields="coefficentFields",
            @change="onCoefficientChange($event)",
            @select-mounted="onSelectMounted($event)",
            @select-initial-load="onSelectInitialLoad($event)"
          )
        div(v-else)
          .metrics-formula-label 
            div {{ localesData.labels.formula }}
            .q-pl-xs ((<span class="coefficient1">k1</span> + <span class="coefficient2">k2</span>) * <span class="coefficient3">k3</span>) / <span class="coefficient4">k4</span>

          coefficient(
            v-for="index in 4",
            highlight,
            :key="index",
            :index="index",
            :locales-data="localesData",
            :value="coefficentsValue?.[`coefficient_${index}`]",
            :coefficent-fields="coefficentFields",
            @change="onCoefficientChange($event)",
            @select-mounted="onSelectMounted($event)",
            @select-initial-load="onSelectInitialLoad($event)"
          )
      .metric-row
        component.field__description(
          :is="getFieldComponent('text')",
          :required="getFieldRequire('description')",
          :label="getFieldLabel('description')",
          :initial-value="getFieldValue('description')",
          @change="onChange(getField('description'), $event)"
        ) 

      hr.separator(v-if="metricIndex < metricsCount - 1")
</template>

<script setup>
import { computed } from "vue";
import stringField from "@/components/shared/general_components/fields/stringField.vue";
import floatField from "@/components/shared/general_components/fields/floatField.vue";
import selectField from "@/components/shared/general_components/fields/selectField.vue";
import textField from "@/components/shared/general_components/fields/textField.vue";
import coefficient from "./Coefficient.vue";
import _ from "lodash";

const props = defineProps({
  // Contains list of fields (from backend) in a single row, each field contains:
  // * label - translated label on input
  // * name - input name, in english
  // * value - value of field
  fields: { type: Array, default: () => [], required: true },
  // Contains list of fields (from backend) in a single row, each field contains:
  // * label - translated label on input
  // * name - input name, in english
  // * options_path - path to fetch content
  // * options_params - params to pass when fetching content. Might not exist
  coefficentFields: { type: Array, default: () => [], required: true },
  // Contains values of default coefficients for us to know how can we reset value.
  // Currently structure looks like this:
  // { "coefficient_1": { "type": "number", "value": "1" }, "coefficient_2": { "type": "number", "value": "0" },
  //   "coefficient_3": { "type": "number", "value": "1" }, "coefficient_4": { "type": "number", "value": "1" } }
  defaultCoefficients: { type: Object, default: () => {}, required: true },
  metricIndex: { type: Number, required: true }, // Index of metric in list
  metricsCount: { type: Number, required: true }, // Metrics count
  localesData: { type: Object, default: () => {}, required: true }, // Locales
  deleted: { type: Boolean, default: false, required: false }, // Should we hide metric
});

const emit = defineEmits([
  "change",
  "delete",
  "recover",
  "metric-init",
  "metric-select-mounted",
  "metric-select-initial-load",
]);

// List of fields we can render dynamically (based on backend content) in a single row
const renderableFields = computed(() =>
  props.fields.filter(field => {
    const renderField = Object.hasOwn(field, "render") ? field.render : true;
    const rendered = renderField && field.name !== "description";

    return rendered;
  }),
);
const typeField = computed(() => props.fields.find(field => field.name == "metric_type"));
const coefficentsValue = computed(() => props.fields.find(field => field.name == "coefficients").value);
const metricId = computed(() => props.fields.find(field => field.name == "id").value); // null for new records

const rowClass = field => {
  return {
    field__item: field.name !== "description",
    field__description: field.name === "description",
  };
};

const getFieldComponent = type => {
  if (type === "string") {
    return stringField;
  } else if (type === "float") {
    return floatField;
  } else if (type === "text") {
    return textField;
  } else if (type === "select") {
    return selectField;
  } else {
    return undefined;
  }
};

const getField = name => {
  return props.fields.find(field => field.name == name);
};

const getFieldRequire = name => {
  const field = getField(name);
  const require = Object.hasOwn(field, "require") ? field.require : false;

  return require;
};

const getFieldLabel = name => {
  const field = getField(name);

  return field.label;
};

const getFieldValue = name => {
  const field = getField(name);

  return field.value;
};

const getFieldOptionsPath = name => {
  const field = getField(name);
  const path = field?.options_path || undefined;

  return path;
};

const getFieldOptionsParams = name => {
  const field = getField(name);
  const params = field?.options_params || {};

  return params;
};

const onSelectMounted = fieldName => {
  emit("metric-select-mounted", fieldName);
};

const onSelectInitialLoad = fieldName => {
  emit("metric-select-initial-load", fieldName);
};

const onChange = (field, args) => {
  const newField = { ...field, value: args.value };
  emit("change", newField);

  // We need to reset coefficients when change type to avoid sending old value there (which could happen if you change formula to simple metric)
  if (field.name === "metric_type") {
    const field = getField("coefficients");

    emit("change", { ...field, value: props.defaultCoefficients });
  }
};

// When we tracked changes in coefficient we need to ensure fields on Form.vue would be updates since it's source of truth
// Changes are merged into field :coefficients (which store coefficents data: k1, k2, k3, k4 and containd type and value)
// for each of them
const onCoefficientChange = data => {
  const coefficientsIndex = data.index;
  const inputType = data.fieldType;
  const args = data.args;
  const coefficientName = `coefficient_${coefficientsIndex}`;
  const field = getField("coefficients");

  if (inputType === "type") {
    // Select type of coefficient
    if (args.value === "number") {
      const newCoefficients = { ...field.value, [coefficientName]: { type: args.value, value: 1 } };

      onChange(field, { value: newCoefficients });
    } else {
      // We need to reset value when coefficient type changed to avoid issues with old value
      const newCoefficients = { ...field.value, [coefficientName]: { type: args.value, value: null } };

      onChange(field, { value: newCoefficients });
    }
  } else if (inputType === "number") {
    // Select value of coefficient (number)
    const existingType = field.value[coefficientName].type;
    const newCoefficients = { ...field.value, [coefficientName]: { type: existingType, value: args.value } };

    onChange(field, { value: newCoefficients });
  } else if (inputType === "statuses") {
    // Select value of coefficient (statuses)
    const existingType = field.value[coefficientName].type;
    const statusIds = args.map(status => status.value); // args here is list since we can have multiple statuses selected
    const newCoefficients = { ...field.value, [coefficientName]: { type: existingType, value: statusIds } };

    onChange(field, { value: newCoefficients });
  } else {
    return;
  }
};

const onDelete = () => {
  emit("delete", metricId.value);
};

const onRecover = () => {
  emit("recover", metricId.value);
};
</script>

<script>
export default {
  name: "ActionsMetricsMetric",
};
</script>

<style lang="scss">
.metric__settings__menu {
  color: var(--grid-actions-menu-color);
  background: var(--grid-actions-menu-background);
}
</style>

<style lang="scss" scoped>
@import "../../../../../assets/styles/metrics/coefficients.scss";

.fade-enter-active {
  transition: transform 0.3s ease-out, opacity 0.2s ease-out;
}
.fade-leave-active {
  transition: transform 0.2s ease-in, opacity 0.3s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.removed-badge {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;

  .removed-badge__recover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.metric {
  .metric-settings {
    display: flex;
    justify-content: space-between;

    .settings__name {
      color: var(--modal-form-card-color);
      font-weight: 600;
      font-size: 18px;
    }

    .settings__options {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--index-grid-actions-icon);
      border-radius: 8px;
      width: 36px;
      height: 36px;
      cursor: pointer;

      .options__icon {
        :deep(path) {
          fill: var(--index-grid-actions-icon-fill);
        }
      }

      &:hover {
        background-color: #5b7aff;

        .options__icon {
          :deep(path) {
            fill: white;
          }
        }
      }
    }
  }

  .metric-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 15px;
    flex-wrap: wrap;

    .field__item {
      flex-grow: 1;
      width: 15%;
    }

    .field__description {
      width: 100%;
    }
  }

  .separator {
    margin-top: 35px;
    margin-bottom: 35px;
    width: 250px;
  }

  .metrics-formula-label {
    color: var(--modal-form-card-color);
    display: flex;
    justify-content: center;
    font-weight: 500;
    margin-top: 10px;
  }
}
</style>
