import addIssue from "../../view/issues/new";
import addIssueCenter from "../../view/issue_center/new";
import addBuilding from "../../view/buildings/new";
import addFacility from "../../view/facilities/new";
import addPost from "../../view/posts/new";
import addFloor from "../../view/floors/new";
import addRoom from "../../view/rooms/new";
import addRoomType from "../../view/room_types/new";
import addUser from "../../view/users/new";
import addStore from "../../view/stores/new";
import addWorkGroup from "../../view/work_groups/new";
import addWork from "../../view/works/new";
import addMeasureUnit from "../../view/measure_units/new";
import addCompany from "../../view/companies/new";
import addDocument from "../../view/documents/new";
import addService from "../../view/services/new";
import addWorkCategory from "../../view/work_categories/new";
import addMaterial from "../../view/materials/new";
import addContract from "../../view/contracts/new";
import addPprEquipment from "../../view/ppr/equipments/new";
import addPprWork from "../../view/ppr/works/new";
import addPprFrequencyWork from "../../view/ppr/frequency_works/new";
import addPprGroupSystem from "../../view/ppr/group_systems/new";
import addPprWorkGroup from "../../view/ppr/work_groups/new";
import addPprSystem from "../../view/ppr/systems/new";
import addEmployee from "../../view/employees/new";
import addIllnessStatus from "../../view/illness_statuses/new";
import addEmployeeStatus from "../../view/employee_statuses/new";
import addCovidTest from "../../view/covid_tests/new";
import addMeasuringMeter from "../../view/measuring/meters/new";
import addMeasuringMeterType from "../../view/measuring/meter_types/new";
import addMeasuringMeterReading from "../../view/measuring/meter_readings/new";
import addChecklist from "../../view/checklists/new";
import addWorkflow from "../../view/workflows/new";
import addCleaningGroup from "../../view/cleaning_groups/new";
import addCleaningJournal from "../../view/cleaning_journals/new";
import addCleaningTariff from "../../view/cleaning_tariffs/new";
import addPprScheduler from "../../view/ppr/scheduler/new";
import addPriorities from "../../view/priorities/new";
import addIssueCenterFilter from "../../view/issue_center/pages/custom_filters/forms/new";
import addSensors from "../../view/sensors/new";
import addDynamicIssue from "../../view/dynamic_issues/new";

export default {
  issues: addIssue,
  issue_center: addIssueCenter,
  buildings: addBuilding,
  facilities: addFacility,
  posts: addPost,
  floors: addFloor,
  rooms: addRoom,
  room_types: addRoomType,
  users: addUser,
  stores: addStore,
  work_groups: addWorkGroup,
  works: addWork,
  measure_units: addMeasureUnit,
  companies: addCompany,
  documents: addDocument,
  services: addService,
  work_categories: addWorkCategory,
  materials: addMaterial,
  contracts: addContract,
  ppr_equipments: addPprEquipment,
  ppr_works: addPprWork,
  ppr_frequency_works: addPprFrequencyWork,
  ppr_group_systems: addPprGroupSystem,
  ppr_work_groups: addPprWorkGroup,
  ppr_systems: addPprSystem,
  employees: addEmployee,
  illness_statuses: addIllnessStatus,
  employee_statuses: addEmployeeStatus,
  covid_tests: addCovidTest,
  measuring_meters: addMeasuringMeter,
  measuring_meter_types: addMeasuringMeterType,
  measuring_meter_readings: addMeasuringMeterReading,
  checklists: addChecklist,
  workflows: addWorkflow,
  cleaning_groups: addCleaningGroup,
  cleaning_journals: addCleaningJournal,
  cleaning_tariffs: addCleaningTariff,
  ppr_scheduler: addPprScheduler,
  priorities: addPriorities,
  issue_center_filters: addIssueCenterFilter,
  sensors: addSensors,
  dynamic_issues: addDynamicIssue,
};
