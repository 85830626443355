<template lang="pug">
.expenses__row
  expenses-row-item(v-for="item in items", :key="item.label", :item="item")
</template>

<script setup>
import ExpensesRowItem from "./ExpensesRowItem.vue";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { backend } from "@/api";

import { useEmitter } from "@/services/useEmitter";
import { generateFiltersParams } from "@/services/generateFiltersParams";

import { useStore } from "@/store";

const emitter = useEmitter();

const store = useStore();

const props = defineProps({
  path: { type: String, required: true },
});

const items = ref();

const getData = async () => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["expenses_dashboard"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  const data = (await backend.collection(props.path, { query: localQuery, filters: JSON.stringify(localFilters) }))
    .data;
  // console.log(data)
  items.value = data;
};

onMounted(async () => {
  await getData();

  emitter.on("refresh-expenses-dashboard-data", getData);
});

onBeforeUnmount(() => {
  emitter.off("refresh-expenses-dashboard-data");
});
</script>

<style lang="scss">
.expenses {
  &__row {
    display: flex;
    align-items: stretch;
    gap: 25px;
  }
}
</style>
