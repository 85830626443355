import ExpensesRow from "./ExpensesRow";
import ExpensesBarChart from "./ExpensesBarChart";
import ExpensesPieChart from "./ExpensesPieChart";
import ExpensesColumnChart from "./ExpensesColumnChart";

export default {
  expensesRow: ExpensesRow,
  expensesBarChart: ExpensesBarChart,
  expensesPieChart: ExpensesPieChart,
  expensesColumnChart: ExpensesColumnChart,
};
