<template lang="pug">
.dateline__container
  .dateline
    template(v-if="!loading")
      .dateline__column(
        v-for="(day, index) in columns",
        :key="day.toString()",
        :ref="el => (columnRefs[index] = el)",
        :class="{ 'dateline__column--weekend': isWeekend(day) }"
      )
        .dateline__column__header(@click="openDate(day)")
          .dateline__column__header__time(:class="{ 'dateline__column__header__time--selected': isCurrentDate(day) }") {{ getDateWithDay(day) }}
            .dateline__column__header__time__count(
              :class="{ 'dateline__column__header__time__count--empty': getCountLabel(day) === 0 }"
            ) {{ getCountLabel(day) }}

        .dateline__column__content
          .dateline__row(v-for="issue in getLayoutIssues(day)", :key="issue.id")
            .dateline__card(@click="openShow(issue)", :style="getCardColor(issue.status_color)")
              .dateline__card__header
                .dateline__card__header__id №{{ issue.id }}
                .dateline__card__header__status(v-if="stateRule.value", :style="getStatusColor(issue.status_color)") {{ issue.state }}

              .dateline__card__content
                div(v-for="rule in filteredDisplayRules", :key="issue.id + rule.name")
                  b {{ `${rule.label}: ` }}
                  span(v-if="isDateFields(rule)") {{ getFormattedDate(issue[rule.name]) || "-" }}
                  div(v-else-if="rule.name === 'description'", v-html="issue[rule.name] || '-'")
                  span(v-else) {{ issue[rule.name] || "-" }}

    template(v-else)
      .dateline__column(v-for="(day, index) in [0, 1, 2, 3, 4, 5, 6]", :key="day.toString()")
        .dateline__column__header
          .dateline__column__header__time
            q-skeleton(type="text", width="100px")

        .dateline__column__content
          .dateline__row(v-for="issue in [0, 1, 2, 3, 4]", :key="issue")
            .dateline__card.dateline__card--skeleton
              .dateline__card__header
                .dateline__card__header__id
                  q-skeleton(type="text", width="20px")

              .dateline__card__content
                q-skeleton(type="text")

  .footer
    span {{ `${issueCenterLocales.now} ${currentTime}` }}
</template>

<script setup>
import backend from "@/api";
import { issueCenterLocales } from "@/services/useLocales";
import { useEmitter } from "@/services/useEmitter";

import { onMounted, onBeforeUnmount, ref, computed, nextTick } from "vue";
import { handleError } from "@/services/handleErrors";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { useStore } from "@/store";

import { toDate, format, formatISO, add, sub } from "date-fns";
import { currentLocale } from "@/services/useLocales";

import i18n from "@/plugins/vue-i18n";

const emitter = useEmitter();

const store = useStore();

emitter.on("createdNewIssue", e => onIssueCreated(e));
emitter.on("updatedIssue", e => onIssueUpdated(e));
emitter.on("archivedIssue", e => onIssueArchived(e));

const columnRefs = ref([]);

const columnsByDay = ref({});

const columns = ref([]);

const currentHour = ref();
const displayRules = ref([]);
const issueActions = ref();

const loading = ref(true);

const props = defineProps({
  firstDate: { type: Date, default: undefined, required: true },
  lastDate: { type: Date, default: undefined, required: true },
  initialDate: { type: Date, default: undefined, required: false },
  displayRules: { type: Array, default: () => [], required: true },
  mode: { type: String, default: undefined, required: false },
});

const filteredDisplayRules = computed(() => {
  return props.displayRules.filter(el => el.name !== "state" && (el.value === true || el.value === "true"));
});

const stateRule = computed(() => props.displayRules.find(el => el.name === "state"));

const emit = defineEmits(["set-loading"]);

const getLayoutIssues = day => {
  return columnsByDay.value[day]?.issues;
};

const initializeColumns = () => {
  const result = [];

  for (let i = 1; i <= 7; i++) {
    if (i > 1) result.push(add(props.firstDate, { days: i - 1 }));
    else result.push(props.firstDate);
  }

  columns.value = result;
};

const isCurrentDate = date => {
  const currentDate = getCurrentDate();

  return (
    date.getDate() === currentDate.getDate() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear()
  );
};

const isWeekend = day => {
  return day.getDay() === 0 || day.getDay() === 6;
};

const getCardColor = color => {
  return color ? `background: ${color + "33"}` : null;
};
const getStatusColor = color => {
  return color ? `background: ${color}` : null;
};

const getInitialColumnsData = async () => {
  for (let i = 0; i < columns.value.length; i++) {
    await getIssuesByColumn(columns.value[i]);
  }
};

const getDayOfWeek = day => {
  return i18n["messages"][currentLocale.value]["date"]["daysShort"][day];
};

const getRequestParams = timeParam => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["issue_center"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  localFilters["type"] = "MaintenanceIssue";
  localFilters["finish_date_plane#from"] = `${getISODate(timeParam)}`;
  localFilters["finish_date_plane#to"] = `${getISODate(sub(add(timeParam, { days: 1 }), { seconds: 1 }))}`;

  return {
    json: JSON.stringify({
      table: {
        ignore_pagination: true,
        except_filters: {},
        query: localQuery,
        filters: localFilters,
      },
    }),
  };
};

const getIssuesByColumn = async timeParam => {
  try {
    const params = getRequestParams(timeParam);

    const response = await backend.index("api/v3/situational_center/issues", { params });

    issueActions.value = response.data.actions;

    store.commit("setIssueActionsInIssueCenter", issueActions.value);

    columnsByDay.value = {
      ...columnsByDay.value,
      [timeParam]: { issues: response.data.data, count: response.data.count },
    };
  } catch (e) {
    await handleError(e);
  }
};

const getCurrentDate = () => {
  return toDate(new Date());
};

const getISODate = timeParam => {
  return formatISO(
    new Date(
      timeParam.getFullYear(),
      timeParam.getMonth(),
      timeParam.getDate(),
      timeParam.getHours(),
      timeParam.getMinutes(),
      timeParam.getSeconds(),
    ),
  );
};

const getDateWithDay = timeParam => {
  return `${getDayOfWeek(timeParam.getDay())} – ${format(timeParam, "dd.MM.yyyy")}`;
};

const getCountLabel = day => {
  return columnsByDay.value[day]?.count || 0;
};

const isDateFields = rule => {
  return (
    rule.name === "finish_date_plane" ||
    rule.name === "created_at" ||
    rule.name === "updated_at" ||
    rule.name === "dead_line"
  );
};

const getCurrentTime = () => {
  return format(getCurrentDate(), "dd.MM.yyyy");
};

const currentTime = ref(getCurrentTime());

const changeDates = async prefix => {
  let newDate;

  if (prefix === "+") {
    newDate = add(props.initialDate, { days: 7 });
  } else {
    newDate = sub(props.initialDate, { days: 7 });
  }

  changeInitialDates(newDate);
  nextTick(async () => {
    loading.value = true;

    initializeColumns();
    await getInitialColumnsData();

    loading.value = false;
  });
};

const getFormattedDate = date => {
  if (!date) return;
  // We need to ensure date is formatted as ISO string.
  const dataArray = date.split("T");

  if (dataArray.length > 1) {
    const dateInstance = new Date(date);

    return format(dateInstance, "dd.MM.yyyy, HH:mm");
  }
};

const openShow = rowValue => {
  emit("openShow", rowValue);
};

const openDate = date => {
  const currentTime = getCurrentDate();
  const newDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    currentTime.getHours(),
    currentTime.getMinutes(),
  );

  emit("openDate", newDate);
};

const changeInitialDates = (date = getCurrentDate()) => {
  const initialDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const currentDay = initialDate.getDay() === 0 ? 7 : initialDate.getDay();

  const firstDate = sub(initialDate, { days: currentDay - 1 });
  const lastDate = add(initialDate, { days: 7 - currentDay });

  emit("changeDates", {
    initialDate: initialDate,
    firstDate: firstDate,
    lastDate: lastDate,
  });
};

const onIssueArchived = event => {
  const issueDate = new Date(event.finish_date_plane);
  const data = new Date(issueDate.getFullYear(), issueDate.getMonth(), issueDate.getDate());

  if (!columnsByDay.value[data]) columnsByDay.value[data] = { issues: [], count: 0 };
  if (!columnsByDay.value[data].issues) return;

  const issueIndex = columnsByDay.value[data].issues.findIndex(el => el.id === event.id);

  if (issueIndex === -1) return;

  columnsByDay.value[data].issues.splice(issueIndex, 1);

  columnsByDay.value[data].count -= 1;
};

const onIssueUpdated = event => {
  const issueDate = new Date(event.finish_date_plane);
  const data = new Date(issueDate.getFullYear(), issueDate.getMonth(), issueDate.getDate());

  let currentIssueDate = null;
  let currentIssueIndex = null;

  for (let i in columnsByDay.value) {
    for (let j = 0; j < columnsByDay.value[i].issues.length; j++) {
      if (columnsByDay.value[i].issues[j].id === event.id) {
        currentIssueDate = i;
        currentIssueIndex = j;
      }
    }
  }

  if (currentIssueDate !== null && currentIssueIndex !== null && currentIssueDate === data) {
    columnsByDay.value[data].issues.splice(currentIssueIndex, 1, event);
  } else if (currentIssueDate !== null && currentIssueIndex !== null) {
    columnsByDay.value[currentIssueDate].issues.splice(currentIssueIndex, 1);
    columnsByDay.value[currentIssueDate].count -= 1;

    if (!columnsByDay.value[data]) columnsByDay.value[data] = { issues: [], count: 0 };
    columnsByDay.value[data].issues.unshift(event);
    columnsByDay.value[data].count += 1;
  }
};

const onIssueCreated = event => {
  const issueDate = new Date(event.finish_date_plane);
  const data = new Date(issueDate.getFullYear(), issueDate.getMonth(), issueDate.getDate());

  if (!columnsByDay.value[data]) columnsByDay.value[data] = { issues: [], count: 0 };

  const persistedIssue = columnsByDay.value[data].issues.findIndex(el => el.id === event.id);

  if (persistedIssue !== -1) return;

  columnsByDay.value[data].issues.unshift(event);
  columnsByDay.value[data].count += 1;
};

const initializeData = async () => {
  if (props.mode === "dateline") {
    loading.value = true;
    columns.value = [];
    columnsByDay.value = {};

    initializeColumns();

    await getInitialColumnsData();
    loading.value = false;
  }
};

onMounted(async () => {
  await initializeData();

  emitter.on("issue-center-dateline-refresh-data", initializeData);
});

onBeforeUnmount(() => {
  emitter.off("issue-center-dateline-refresh-data");
  emitter.off("createdNewIssue");
  emitter.off("updatedIssue");
  emitter.off("archivedIssue");
});

defineExpose({ changeDates });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/issue_center.scss";

.dateline {
  grid-template-columns: repeat(7, calc(100% / 7));

  &__column__header {
    cursor: pointer;

    &__time--selected {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--header-title-color);
      background: var(--issue-center-active-time-color);
    }
  }

  &__row {
    &__card {
      cursor: pointer;
    }
  }
}
</style>
