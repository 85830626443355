<template>
  <div>
    <div style="text-align: right">
      <img alt="" :src="require(`../../../../assets/images/amelia_logo.jpg`)" style="right: 10px; width: 10%" />
    </div>

    <div id="calibre_link-0" class="calibre">
      <p class="block_">
        <span class="text_"><span class="calibre1" /></span><span class="text_"><span class="calibre1" /></span>
      </p>
      <p class="block_1">&nbsp;</p>
      <p class="block_2">Акт оказанных услуг на объекте</p>
      <p class="block_3">&nbsp;</p>
      <p class="block_4">
        <span class="text_1"><span class="tab">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
        <span class="text_1"><span class="tab">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
        <span class="text_1"><span class="tab">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
        <span class="text_1"><span class="tab">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
        <span class="text_1"><span class="tab">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
        <span class="text_1"><span class="tab">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
      </p>
      <p class="block_5">&nbsp;</p>
      <p class="block_6">
        Объект:
        <span style="font-weight: normal">{{
          data.building_title || "__________________________________________________"
        }}</span>
      </p>
      <p class="block_4">
        <span class="text_1"><span class="calibre1" /></span><span class="text_1">(Подразделение, адрес)</span>
      </p>
      <p class="block_3">&nbsp;</p>
      <p class="block_4">
        <span class="text_2">Дата начала работ:</span>
        <span class="text_1">&nbsp;{{ data.issue_started || "_______________________________" }}</span>
      </p>
      <p class="block_4">
        <span class="text_2">Дата завершения работ:</span>
        <span class="text_1">&nbsp;{{ data.issue_finished || "_______________________________" }}</span>
      </p>
      <p class="block_4">
        <span class="text_2">№ заявки:</span>
        <span class="text_1">&nbsp;{{ data.issue_id || "_______________________________" }}</span>
      </p>
      <p class="block_5">&nbsp;</p>
      <p class="block_6">Описание заявки:</p>
      <p class="block_5" v-html="data.description || '-'" />
      <p class="block_5">&nbsp;</p>
      <p class="block_6">Выполненные работы:</p>
      <table class="table_">
        <tbody class="calibre2">
          <tr class="calibre3">
            <td class="td_">
              <p class="block_7">№</p>
              <p class="block_8">&nbsp;</p>
            </td>
            <td class="td_1">
              <p class="block_7">Наименование работ</p>
            </td>
            <td class="td_2">
              <p class="block_7">Ед. измерения</p>
            </td>
            <td class="td_2">
              <p class="block_7">Объем работ</p>
            </td>
            <td class="td_18">
              <p class="block_7">Цена</p>
            </td>
            <td class="td_18">
              <p class="block_7">Сумма</p>
            </td>
          </tr>
          <tr v-for="(work, index) in data.completed_works" :key="index" class="calibre3">
            <td class="td_5">
              <p class="block_7">
                {{ index + 1 }}
              </p>
            </td>
            <td class="td_6">
              <p class="block_10">
                {{ work.title }}
              </p>
            </td>
            <td class="td_7">
              <p class="block_10">
                {{ work.measure_unit }}
              </p>
            </td>
            <td class="td_8">
              <p class="block_10">
                {{ work.count }}
              </p>
            </td>
            <td class="td_8">
              <p class="block_10">
                {{ work.cost }}
              </p>
            </td>
            <td class="td_8">
              <p class="block_10">
                {{ work.sum }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="block_11">&nbsp;</p>
      <p class="block_12">Запасные части и расходные материалы, использованные при выполнении работ:</p>
      <table class="table_">
        <tbody class="calibre2">
          <tr class="calibre3">
            <td class="td_15">
              <p class="block_7">№</p>
              <p class="block_8">&nbsp;</p>
            </td>
            <td class="td_16">
              <p class="block_7">Наименование материала</p>
            </td>
            <td class="td_17">
              <p class="block_7">Ед. измерения</p>
            </td>
            <td class="td_18">
              <p class="block_7">Кол-во</p>
            </td>
            <td class="td_18">
              <p class="block_7">Цена</p>
            </td>
            <td class="td_18">
              <p class="block_7">Сумма</p>
            </td>
          </tr>
          <tr v-for="(material, index) in data.consumptions" :key="index" class="calibre3">
            <td class="td_19">
              <p class="block_7">
                {{ index + 1 }}
              </p>
            </td>
            <td class="td_20">
              <p class="block_10">
                {{ material.title }}
              </p>
            </td>
            <td class="td_21">
              <p>{{ material.measure_unit }}</p>
            </td>
            <td class="td_22">
              <p class="block_10">
                {{ material.count }}
              </p>
            </td>
            <td class="td_22">
              <p class="block_10">
                {{ material.cost }}
              </p>
            </td>
            <td class="td_22">
              <p class="block_10">
                {{ material.sum }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="block_5">&nbsp;</p>

      <p class="block_16">&nbsp;</p>
      <p class="block_16">&nbsp;</p>
      <table class="table_2">
        <tbody class="calibre2">
          <tr class="calibre3">
            <td class="td_27">
              <p class="block_17">От ЗАКАЗЧИКА:</p>
              <p class="block_16">&nbsp;</p>
              <p class="block_18">_______________________________</p>
              <p class="block_18">(подпись, расшифровка ФИО)</p>
              <p class="block_16">&nbsp;</p>
              <p class="block_18">{{ data.customer_name || "-" }}</p>
            </td>
            <td class="td_28">
              <p class="block_6">От ИСПОЛНИТЕЛЯ:</p>
              <p class="block_5">&nbsp;</p>
              <p class="block_15">_______________________________</p>
              <p class="block_15">(подпись, расшифровка ФИО)</p>
              <p class="block_5">&nbsp;</p>
              <p class="block_15">{{ data.executor_name || "-" }}</p>
              <p class="block_16">&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="block_19">&nbsp;</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      data: this.parentData.data,
    };
  },

  computed: {},

  methods: {},
};
</script>
