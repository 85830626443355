<template lang="pug">
.tab-list(v-if="showSelectedFilters") 
  .tab--active(v-for="(filter, i) in selectedFilters", :key="filter.name", :style="{ background: colors[i] }") 
    div {{ filterText(filter) }}
    .tab__close
      inline-svg(:src="require('@/assets/icons/dynamic-issues/index/close.svg')", @click="removeFilter(filter)")
  .tab--reset(@click="resetAllFilters") {{ currentLocales.clear_all }}
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "@/store";
import { dynamicIssuesLocales } from "@/services/useLocales";
import { parseISO, format } from "date-fns";
import { currentLocale } from "@/services/useLocales";
import chroma from "chroma-js";
import i18n from "@/plugins/vue-i18n";

const props = defineProps({
  grid: { type: String, default: "" }, // Current grid name
  filtersData: { type: Array, default: () => [] }, // List of filters. Example: [ { "label": "Поиск", "name": "query", "type": "search_all" }, ... ]
});

const store = useStore();
const formatDateFormat = "dd.MM.yyyy";

const emit = defineEmits(["reset"]);

const i18nLocales = computed(() => i18n["messages"][currentLocale.value]);
const colors = computed(() => {
  const length = selectedFilters.value.length;
  const startColor = "#D8DFFF";
  const middleColor = "#D6EEE3";
  const endColor = "#FFD6D3";

  if (length == 1) {
    return [startColor];
  } else if (length == 2) {
    return [startColor, endColor];
  } else if (length == 3) {
    return [startColor, middleColor, endColor];
  } else {
    return chroma.scale([startColor, middleColor, endColor]).mode("lch").colors(selectedFilters.value.length);
  }
});
const currentLocales = computed(() => dynamicIssuesLocales.value.filters);
const showSelectedFilters = computed(() => selectedFilters.value.length > 0);
const filtersDataMap = computed(() => Object.assign({}, ...props.filtersData.map(item => ({ [item.name]: item })))); // Represent filters as object
const selectedFilters = computed(() => {
  // Create object with nessesary data for output. For float dynamic fields we group values into one:
  // * value: "123 - 456"
  // * value: "01.02.2023 - 01.03.2023"
  const filters = store.state.grid[props.grid]["filters"];
  const excludedCommonKeys = [];

  const splitFilters = Object.entries(filters)
    .map(filter => {
      const name = filter[0];
      const value = Array.isArray(filter[1]) ? filter[1].map(item => item.value) : filter[1];
      const type = filtersDataMap.value[name].type;
      const commonKey = filtersDataMap.value[name].common_key;
      const label = filtersDataMap.value[name].label;
      const newFilter = { label, value, name, type, commonKey, names: [] };

      // Mark child as removable if it was grouped with a previuos child
      if (excludedCommonKeys.includes(newFilter.commonKey)) {
        return null;
      }

      // Is it a dynamic value which needs to be represented as a range?
      // In that case we update value with range value and exclude second range value to display only 1 tab
      if (newFilter.type == "float" && newFilter.commonKey) {
        const options = buildDynamicRangeContent(newFilter, filters);
        newFilter.value = options.value;
        newFilter.names = options.names;
        newFilter.type = "float range"; // change type to avoid potentail issues
        excludedCommonKeys.push(newFilter.commonKey);
      } else if (newFilter.type == "date" && newFilter.commonKey) {
        const options = buildDynamicRangeContent(newFilter, filters);
        newFilter.value = options.value;
        newFilter.names = options.names;
        newFilter.type = "date range"; // change type to avoid date conversion error
        excludedCommonKeys.push(newFilter.commonKey);
      }

      return newFilter;
    })
    .filter(filter => filter != null); // Remove values we grouped into one (such values are marked as null);

  return splitFilters;
});

const buildDynamicRangeContent = (filter, filters) => {
  const matchedTexts = filter.name.match(/[a-z]+\b/g);
  const keyName = matchedTexts[matchedTexts.length - 1]; // Extract name from text like tech_passport_values_attributes[1][value][to]
  const secondKeyName = keyName == "from" ? "to" : "from"; // Based on first name we search for second name
  const secondKeyFullname = `${filter.commonKey}[${secondKeyName}]`;
  const secondValue = filters[secondKeyFullname];
  let options = {};

  if (secondValue !== null && secondValue !== undefined) {
    options = { [keyName]: filter.value, [secondKeyName]: secondValue, names: [filter.name, secondKeyFullname] }; // set names to be able to remove all dynamic float values at once
  } else {
    options = { [keyName]: filter.value, names: [] };
  }

  const fromValue = formatRangeValue(filter, options.from) || "...";
  const toValue = formatRangeValue(filter, options.to) || "...";
  const value = `${fromValue} - ${toValue}`;

  return { value, names: options.names };
};

const formatRangeValue = (filter, value) => {
  if (filter.type == "date") {
    return value ? format(parseISO(value), formatDateFormat) : value; // value can be undefined when we have ... - 01.01.2023 or 01.01.2023 - ...
  } else {
    return value;
  }
};

const filterValue = filter => {
  if (filter.type == "checkbox") {
    return filter.value ? currentLocales.value.checked : currentLocales.value.unchecked;
  } else if (filter.type == "date") {
    return format(parseISO(filter.value), formatDateFormat);
  } else if (filter.type == "select") {
    return `${filter.value.length} ${i18nLocales.value.items}`;
  } else {
    return filter.value;
  }
};

const filterText = filter => {
  const value = filterValue(filter);

  return `${filter.label} (${value})`;
};

const removeFilter = filter => {
  if (filter.names.length > 1) {
    // This is true when it has mupltiple filters (dynamic values)
    for (const filterName of filter.names) {
      store.commit("resetFilter", { grid_name: props.grid, filter: filterName });
    }
  } else {
    store.commit("resetFilter", { grid_name: props.grid, filter: filter.name });
  }

  emit("reset");
};

const resetAllFilters = () => {
  store.commit("removeAllFilters", { grid_name: props.grid });
  emit("reset-all");
};
</script>

<style scoped lang="scss">
.tab-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: flex-start;
  margin-bottom: 15px;

  .tab {
    &__close {
      cursor: pointer;
      display: flex;
    }

    &--active {
      color: var(--dynamic-issues-selection-tabs-color);
      border-radius: 17px;
      padding: 4px 12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--reset {
      color: var(--dynamic-issues-selection-tabs-color);
      background-color: var(--dynamic-issues-selection-tabs-background);
      border: 0.5px solid var(--dynamic-issues-selection-tabs-border-color);
      border-radius: 17px;
      padding: 4px 12px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
</style>
